import MainView from "./Views/mainPage";
import AboutView from "./Views/aboutView";
import ContactsView from "./Views/contactsView";

import MainPresenter from "./Presenters/mainPresenter";
import ContactsPresenter from "./Presenters/contactsPresenter";

import Navbar from "./Views/navBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css"

function App() {
  return (
    <div>
      <BrowserRouter>
        {/* <Navbar/> */}
        <Routes>
          <Route path="/" element={<MainPresenter />} />
          <Route path="/about" element={<AboutView />} />
          <Route path="/contact" element={<ContactsPresenter />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
