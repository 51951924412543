import React, { useRef } from 'react'
import {Parallax, ParallaxLayer} from '@react-spring/parallax'
import Navbar from './navBar';


export default function ContactsView() {
  const ref = useRef();

  return (
    <div>
        <Parallax className="main" pages={1} ref = {ref}>
            
        <ParallaxLayer 
              offset={0}
              speed={0.5}
              factor={6}
              style={{

              }}
              >
                <Navbar/>
                <div 
                  className='contacts-horizontal'
                >
                    <div className='contacts-div'>
                        <a href="https://www.linkedin.com/in/hassanadia/" target="_blank" rel="noreferrer" > 
                            <img src='https://imgur.com/2yvQfBO.png' className='contacts-img'/>
                        </a>
                    </div>
                    <div className='contacts-div'>
                        <a href="https://github.com/DiaHassan" target="_blank" rel="noreferrer">
                            <img src='https://imgur.com/UvoBmvh.png' className='contacts-img'/>
                        </a>
                    </div>
                    <div className='contacts-div'>
                        <a href="https://www.instagram.com/hassandia_/" target="_blank" rel="noreferrer">
                            <img src='https://imgur.com/KaQsb83.png' className='contacts-img'/>
                        </a>
                    </div>
                    <div className=''>
                            <img src='https://imgur.com/Q55Nhtn.png' className='contacts-img disabled'/>
                            <span className='hide'>Currently unavailable :/</span>
                    </div>
                    
                </div>
              </ParallaxLayer>
        </Parallax>
    </div>
  )
}

