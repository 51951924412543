import React, { useRef } from 'react'
import {Parallax, ParallaxLayer} from '@react-spring/parallax'
import Navbar from './navBar';
import { Link } from 'react-router-dom';


export default function AboutView() {
  const ref = useRef();

  return (
    <div>
        <Parallax className="main" pages={3} ref = {ref}>
            
        <ParallaxLayer 
              offset={0}
              speed={0.5}
              factor={6}
              style={{
                // backgroundColor: "blue",
                backgroundImage: `url("https://imgur.com/iastU4t.png")`,
                backgroundSize: "100%"
              }}
              >
                <Navbar/>
                <div 
                  className='about-horizontal'
                  onClick={() => ref.current.scrollTo(1)}
                >
                  <div className='about-div'>
                    <img src='https://i.imgur.com/vWoHwiC.png' className='about-image'/>
                  </div>
                  <div className='about-text'>
                    ► I am a second-year computer engineering student at KTH. I chose KTH for its high-quality education and practical approach to learning. The computer engineering program here is a 5-year program with two years of specialized master's study. I am excited to learn from experienced professors and gain the skills and knowledge needed for a successful career. I am confident that KTH's education and training will provide me with a strong foundation to achieve my goals.
                  </div>
                </div>
              </ParallaxLayer>
            
            <ParallaxLayer
              offset={1}
              speed={0.5}
              onClick={() => ref.current.scrollTo(2)}
              style={{
              }}
              >
                <div className='about-horizontal'>
                    <div className='about-text'>
                      ► I am a teaching assistant for the Digital Design course at KTH, where I assist students in understanding course concepts and building circuits on breadboards. As a TA, I have the opportunity to both deepen my own knowledge and help students succeed. I am grateful for the opportunity to be a part of students' educational journeys and am excited to see where this experience takes me in the future.
                    </div>
                  <div className='about-div'>
                      <img src='https://imgur.com/ICas91Z.png' className='about-image'/>
                    </div>
                </div>
            </ParallaxLayer>

            <ParallaxLayer
              offset={2}
              speed={0.5}
              onClick={() =>{ref.current.scrollTo(0)}}
              style={{
                backgroundImage: `url("https://imgur.com/FCIRNYC.png")`,
                backgroundSize: "cover"
              }}
              >
                <div className=''>
                     <span className='to-contacts'> Feel free to <Link to="/contact" className="contacts-word"> contact </Link> me at any time and I will do my best to get back to you as soon as possible!</span>
                </div>
            </ParallaxLayer>

        </Parallax>
    </div>
  )
}

