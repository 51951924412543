import React, { useEffect, useState } from 'react'
import MainView from '../Views/mainPage'
import ContactsView from '../Views/contactsView'
export default function ContactsPresenter() {
    const [user, setUser] = useState()
    

    // window.addEventListener('mousemove',(e) => {
    //     var x = e.clientX
    //     var y = e.clientY
    //     follower.style.left = x + "px";
    //     follower.style.top = y + "px";
    // })
    

    useEffect(() => {

    }, [])


    return (
        <div>
            <ContactsView />
        </div>
    );
}
