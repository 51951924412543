import React, { useRef } from 'react'
import {Parallax, ParallaxLayer} from '@react-spring/parallax'
import Navbar from './navBar';
import { Link } from 'react-router-dom';
// import follow from '../Js/follow';


export default function MainView() {
  const ref = useRef();
  let follower;   
  // let follower = document.getElementById('circle');
  // window.addEventListener('mousemove',(e) => {
  //   var x = e.clientX
  //   var y = e.clientY
  //   follower.style.left = x + 30 + "px";
  //   follower.style.top = y +  30 +"px";
  // })
  return (
    <div>
        <Parallax className="main" pages={4} ref = {ref}>
            
            <ParallaxLayer 
              offset={0}
              speed={0.5}
              factor={6}
              style={{
                backgroundColor: "#000000"
              }}
              >
                <Navbar/>
                <div 
                  className='horizontal'
                  onClick={() => ref.current.scrollTo(1)}
                >
                  <div className='main-text'>
                      <div className='main-hello'>
                        Hello! I'm Hassan Dia
                      </div>
                      <br/>
                      <div className='main-info'>
                        A computer engineer and a finance enthusiast
                      </div>
                    </div>
                  <div className='tempDiv2'>
                    <img src='https://imgur.com/9PNqKm1.png' className='main-image'/>
                   </div>
                </div>
              </ParallaxLayer>

            <ParallaxLayer
              offset={1}
              speed={0.5}
              onClick={() => ref.current.scrollTo(2)}
              >
                <div className='main-project'>
                  <h1>Poke and Woke</h1>
                  <div className='main-image-holder'>
                    <img className='main-progress-i' src='https://imgur.com/Doyg9Bz.png'/>
                    <img className='main-progress-i' src='https://imgur.com/GfQmDQh.png'/>
                    <img className='main-progress-i' src='https://imgur.com/P4YRZsC.png'/>
                  </div>
                  <a href="https://gits-15.sys.kth.se/hdia/Poke-Project" target="_blank" rel="noreferrer" className='main-p-link'>To github!</a>
                </div>
            </ParallaxLayer>

            <ParallaxLayer
              offset={2}
              speed={0.5}
              onClick={() =>{ 
                
                ref.current.scrollTo(3)}}
              >
                <div className='main-project' onClick={() => console.log("test")}>
                <h1>In Progress</h1>
                  <div className='main-image-holder'>
                    <div className='main-progress-l'><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    <div className='main-progress-l'><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    <div className='main-progress-l'><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                  </div>
                </div>
            </ParallaxLayer>

            <ParallaxLayer
              offset={3}
              speed={0.5}
              onClick={() =>ref.current.scrollTo(0)}
              style={{
                backgroundImage: `url("https://imgur.com/FCIRNYC.png")`,
                backgroundSize: "cover"
              }}
              >
                <div className=''>
                     <span className='to-contacts'> Feel free to <Link to="/contact" className="contacts-word"> contact </Link> me at any time and I will do my best to get back to you as soon as possible!</span>
                </div>
            </ParallaxLayer>
        </Parallax>
    </div>
  )
}

